import classnames from 'classnames/bind'

import styles from './Spinner.module.css'
let cx = classnames.bind(styles)

import SpinnerSVG from './rings.svg'

const Spinner = ({ visible, center }) => {
  const spinner_classes = cx({
    spinner: true,
    'spinner-visible': visible,
    'spinner-center': center,
  })

  return (
    <div className={spinner_classes}>
      <SpinnerSVG />
    </div>
  )
}

export default Spinner
