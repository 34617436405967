import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAppStore } from 'utils/appStore'
import styles from '../styles/Home.module.css'
import Button from 'components/Button'

export default function Home() {
  const { user } = useAppStore()
  const router = useRouter()

  useEffect(() => {
    if (user) {
      router.push('/dashboard')
    }
  }, [user])

  return (
    <div className={styles.container}>
      <Head>
        <title>magicnumbr</title>
        <meta name="description" content="magicnumbr" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className={styles.navbar}>
        <h2 className={styles.title}>magicnumbr</h2>
        <Button href="/dashboard" primary>
          Beta Login
        </Button>
      </div>
      <main className={styles.main}>
        <h3>coming soon...</h3>
      </main>
    </div>
  )
}
